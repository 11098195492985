<template>
<div>
  <div class="wrapper">
    <div class="gray-banner">
      <div class="header-image">
        <svg version="1.1" id="svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 400 207.6" style="enable-background:new 0 0 400 207.6;" xml:space="preserve"><path class="st1" :style="svgStyle" d="M299.9,207.6c12.2-2.6,36.2-9.6,58.4-29.6c35.2-31.7,40.4-73.2,41.5-84c0.1,22.4,0.1,44.8,0.2,67.3
               c-6.4,8.5-21,25.2-45.7,36.3C331.2,207.9,310.5,208.2,299.9,207.6z"  stroke="none" fill="#1b9142" fill-rule="evenodd"></path>
           <path class="st2" d="M312.6,207.6c11.7-0.7,34.8-3.8,58.1-19c13.9-9.1,23.3-19.5,29.3-27.3c0,15.5,0,30.9,0,46.4L312.6,207.6z" stroke="none" fill="#ffffff" fill-rule="evenodd"></path>/>
           </svg>
      </div>
      <div class="container">
        <button
              type="button"
              class="btn btn-primary btn-signup float-button"
              data-toggle="modal"
              data-target="#registrationModal"
            >
              Sign Up Today
            </button>
        <div class="row text-center pt-68">
          <div class="col-md-1 back-arrow-div">
            <img
              @click="onUserClick()"
                  src="assets/images/circle-left.png"
                  class="back-arrow-class"/>
          </div>
          <div class="col-md-10 mt-sm-40 logo-container-div ml-60">
            <div
              class="
                logo-container
                float-md-center float-sm-none
                d-flex
                justify-content-center
                align-items-center
                logo-align-center
              "
            >
              <img src="assets/images/canpay_logo.svg" class="logo-img" />
            </div>
          </div>
          <div class="col-md-1 back-arrow-div"></div>
        </div>
        <div class="banner-middle-text">
          Sign up in minutes. Pay with <span class="text-style-2">CanPay </span> at over 800 participating retailers across the country. No fees!
        </div>
        <div class="banner-small-text">
          Join 100,000+ other
          <span class="small-text-bold text-style-2">CanPay </span
          >customers nationwide who are
          already paying with CanPay.
          <div class="text-center mt-50 header-btn-links">
            <button
              type="button"
              class="btn btn-primary btn-signup"
              data-toggle="modal"
              data-target="#registrationModal"
              id="signupBtn"
            >
              Sign Up Today
            </button>
            <p
              class="download-text-link"
              data-toggle="modal"
              data-target="#textLinkModal"
            >
              Text Me a Link to Download the App
            </p>
          </div>
        </div>
      </div>
    </div>

    <section class="text-center">
      <div class="container">
        <p class="pay-canpay mt-140">Why pay with CanPay?</p>
        <div class="row mt-117">
          <div class="col-md-4 mobile-space">
            <img src="assets/images/1.png" class="icons" />
            <p class="top-icon-text">Free to use</p>
          </div>
          <div class="col-md-4 mobile-space">
            <img src="assets/images/2.png" class="icons" />
            <p class="top-icon-text">Skip the ATM Fees</p>
          </div>
          <div class="col-md-4 mobile-space">
            <img src="assets/images/3.png" class="icons" />
            <p class="top-icon-text">
              Pay directly from<br />
              your bank account
            </p>
          </div>
        </div>
        <div class="row mt-100">
          <div class="col-md-4 mobile-space">
            <img src="assets/images/4.png" class="icons" />
            <p class="top-icon-text">Touchless payments</p>
          </div>
          <div class="col-md-4 mobile-space">
            <img src="assets/images/5.png" class="icons" />
            <p class="top-icon-text">
              Sign up and start<br />
              using right away
            </p>
          </div>
          <div class="col-md-4 mobile-space">
            <img src="assets/images/6.png" class="icons" />
            <p class="top-icon-text">
              Instant verification.<br />Start making purchases<br />
              immediately.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="text-center">
      <div class="container mobile-slider-image">
        <p class="pay-canpay mt-170" id="pay-canpay">
          Just 2 minutes to Sign-Up<br />
          and pay with CanPay
        </p>
        <div class="row mt-102 m-0">
          <div
            class="
              d-flex
              flex-column
              col-md-2
              slider3-pager
              mr-0
              show-mobile-first
            "
          >
            <div class="p-2 pm bd-highlight align-right">
              <a href="#"
                ><span class="number-box">1</span>
                <p class="icon-text mt-20">Click Sign Up Today</p>
              </a>
            </div>
            <div class="p-2 pm bd-highlight align-right">
              <a href="#"
                ><span class="number-box">2</span>
                <p class="icon-text mt-20">Verify your phone number</p>
              </a>
            </div>
            <div class="p-2 pm bd-highlight align-right">
              <a href="#"
                ><span class="number-box">3</span>
                <p class="icon-text mt-20">Securely Link your Bank Account</p>
              </a>
            </div>
          </div>
          <div
            class="
              col-md-8
              p-0
              d-flex
              align-middle align-items-center
              show-mobile-third
            "
          >
            <ul class="rslides" id="slider3">
              <li>
                <img src="assets/images/Screen_1.png" class="phone-icon" />
              </li>
              <li>
                <img src="assets/images/Screen_2.png" class="phone-icon" />
              </li>
              <li>
                <img src="assets/images/Screen_3.png" class="phone-icon" />
              </li>
              <li>
                <img src="assets/images/Screen_4.png" class="phone-icon" />
              </li>
              <li>
                <img src="assets/images/Screen_5.png" class="phone-icon" />
              </li>
              <li>
                <img src="assets/images/Screen_6.png" class="phone-icon" />
              </li>
            </ul>
          </div>
          <div
            class="
              d-flex
              flex-column
              col-md-2
              slider3-pager
              ml-0
              show-mobile-second
            "
          >
            <div class="p-2 pm bd-highlight align-left">
              <a href="#"
                ><span class="number-box">4</span>
                <p class="icon-text mt-20">Choose a Quick Access PIN</p>
              </a>
            </div>
            <div class="p-2 pm bd-highlight align-left">
              <a href="#"
                ><span class="number-box">5</span>
                <p class="icon-text mt-20">Add To Homescreen</p>
              </a>
            </div>
            <div class="p-2 pm bd-highlight align-left">
              <a href="#"
                ><span class="number-box">6</span>
                <p class="icon-text mt-20">Pay with CanPay</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-center mt-79">
      <div class="container">
          <button
            type="button"
            class="btn btn-primary btn-signup"
            data-toggle="modal"
            data-target="#registrationModal"
            id="signupBtn"
          >
            Sign Up Today
          </button>
        <p
          class="download-text-link"
          data-toggle="modal"
          data-target="#textLinkModal"
        >
          Text Me a Link to Download the App
        </p>
      </div>
    </section>
    <section class="text-center">
      <div class="container">
        <p class="pay-canpay mt-140">How it works</p>
        <div class="row mx-0 mt-109">
          <div class="col-12 px-0">
            <img src="assets/images/img_2.png" class="img-fluid w-100" />
          </div>
        </div>
        <div class="row mx-0 mt-182">
          <div class="col-12 px-0 mobile-image-scroll mobile-box">
            <img src="assets/images/img_3.png" class="mobile-image" />
          </div>
        </div>
      </div>
    </section>
    <section class="text-center">
      <div class="container">
        <div class="row mt-202">
          <div class="col-md-5">
            <span class="canpay-text d-block">CanPay</span>
            <span class="pay-text d-block">The Better Way to Pay</span>
          </div>
          <div class="col-md-7 video-class">
            <iframe
              class="video-iframe"
              src="https://www.youtube.com/embed/ZiuyJg9YnEM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="row mt-42">
          <div class="col-md-5 signup-margin">
            <button
              type="button"
              class="btn btn-primary btn-signup float-left"
              data-toggle="modal"
              data-target="#registrationModal"
              id="signupBtn"
            >
              Sign Up Today
            </button>
            <p
              class="download-text-link float-left"
              data-toggle="modal"
              data-target="#textLinkModal"
            >
              Text Me a Link to Download the App
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <footer class="page-footer mt-130">
      <!-- Footer Links -->
      <div class="footer-container text-center text-md-left">
        <div class="row footer-top-padding">
          <div class="col-md-3 mt-md-0 mt-3">
            <img
              src="assets/images/canpay-logo-transparent.png"
              class="footer-logo"
            />
          </div>
          <div class="col-md-3 mb-md-0 mb-3">
            <p class="menu-item">Menu</p>
            <ul class="footer-links br-right">
              <li>
                <a href="https://www.canpaydebit.com/">Home</a>
              </li>
              <li>
                <a href="https://www.canpaydebit.com/about-us/">About Us</a>
              </li>
              <li>
                <a href="https://www.canpaydebit.com/contact/">Contact</a>
              </li>
              <li>
                <a href="https://www.canpaydebit.com/about-us/privacy/"
                  >Privacy Policy</a
                >
              </li>
              <li>
                <a href="https://www.canpaydebit.com/about-us/terms-conditions/"
                  >Terms & Conditions</a
                >
              </li>
            </ul>
          </div>
          <div class="col-md-4 mb-md-0 mb-3 pl-120">
            <p class="menu-item footer-active-menu">Get In Touch</p>
            <ul class="footer-links">
              <li>
                <a>Consumer Helpline: 877-564-5174</a>
              </li>
              <li>
                <a>Merchant Support: 888-395-4992</a>
              </li>
              <li>
                <a href="mailto:info@canpaydebit.com"
                  >Email: info@canpaydebit.com</a
                >
              </li>
            </ul>
            <p class="footer-address">
              CanPay<br />
              5856 S. Lowell Blvd. <br />Unit# 32-129<br />
              Littleton, CO 80123
            </p>
          </div>
          <div class="col-md-2 mb-md-0 mb-3 d-flex flex-column social-web">
            <div class="p-2">
              <a href="#"
                ><img
                  src="assets/images/icon-facebook.png"
                  class="social-icons-web"
              /></a>
            </div>
            <div class="p-2">
              <a href="#"
                ><img
                  src="assets/images/icon-twitter.png"
                  class="social-icons-web"
              /></a>
            </div>
           
              <a href="#"
                ><img
                  src="assets/images/icon-linkedin.png"
                  class="social-icons-web"
              /></a>
            </div>
          </div>
        </div>
        <hr class="mt-55" />
        <div class="row copyright-section">
          <div class="footer-copyright col-md-6">
            2016 - 2022, CanPay. All rights reserved.
          </div>
          <div class="container text-center col-md-6 icon-right">
            <a href="#"
              ><img src="assets/images/icon-facebook.png" class="social-icons"
            /></a>
            <a href="#"
              ><img src="assets/images/icon-twitter.png" class="social-icons"
            /></a>
            <a href="#"
              ><img src="assets/images/icon-linkedin.png" class="social-icons"
            /></a>
          </div>
        </div>
</footer>
    <!-- Footer -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="textLinkModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-normal">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Input textbox for Phone Number-->
            <div class="row input-box-row">
              <p class="modal-heading-text">
                Enter your mobile number and CanPay will send you a link to
                download the CanPay App.
              </p>
              <div class="col-12 login-col">
                <div class="form-group">
                  <span class="form-control-icon">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 72 90"
                      style="enable-background: new 0 0 72 90"
                      xml:space="preserve"
                      height="30"
                      width="30"
                    >
                      <path
                        d="M48,70H24c-5.5,0-10-4.5-10-10V12c0-5.5,4.5-10,10-10h24c5.5,0,10,4.5,10,10v48C58,65.5,53.5,70,48,70z M24,6
                        c-3.3,0-6,2.7-6,6v48c0,3.3,2.7,6,6,6h24c3.3,0,6-2.7,6-6V12c0-3.3-2.7-6-6-6H24z"
                      />
                      <path
                        d="M40,14h-8c-1.1,0-2-0.9-2-2s0.9-2,2-2h8c1.1,0,2,0.9,2,2S41.1,14,40,14z"
                      />
                      <circle cx="36" cy="56.4" r="4" />
                    </svg>
                  </span>
                  <input
                    autocomplete="off"
                    class="form-control login-input-box-padding"
                    placeholder="Mobile Number"
                    style="height: 50px"
                    type="text"
                    inputmode="numeric"
                    name="phone"
                    v-mask="'(###) ###-####'"
                    v-model="phone"
                  />
                </div>
                <div class="text-center mt-10">
                  <button
                    type="button"
                    class="btn btn-primary btn-send-now"
                    data-dismiss="modal"
                    @click="sendLink"
                  >
                    Send Now
                  </button>
                </div>
              </div>
              <p class="modal-footer-text">
                By selecting Send Now, you authorize CanPay to send you a text
                message. Standard SMS rates may apply.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="registrationModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="registrationModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="max-width: 902px">
        <div class="modal-content modal-large">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <iframe
              class="iframecanpay"
              src="https://app.canpaydebit.com/registration"
              title="CanPay Registration"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import api from "../api/participantmerchant.js";

export default {
  name: "LandingPage",
  /**
   * @description-
   * gets corporate partner dynamic page
   * @returns {any}
   */
  mounted() {

    this.$intercom.update({
      hide_default_launcher: false,
    });

    $(function () {
      $("#slider3").responsiveSlides({
        manualControls: ".slider3-pager",
        maxwidth: 700,
        timeout: 1500,
        pause: false,   
        pauseControls: false
      });
    });
   
   

  },
  unmounted() {
    var e1 = document.getElementById("app");
    if (e1) {
      e1.style.setProperty("background-color", "#149240");
    }
  },
  created () {
    let self = this;
  },
  data() {
    return {
      isShowing: false,
      corporateColor: null,
      corporateColorSmall: null,
      corporateName: null,
      uniqueVisitors: null,
      corporateLogo: null,
      corporate_url: null,
      display_name: null,
      myStyle: null,
      currentPicked: null,
      signupAppUrl: process.env.VUE_APP_SIGNUP_URL,
      phone: null,
      entered: 0,
      uniqueArray: [],
      svgStyle: "#1b9142",
      canpayClass: "text-style-2",
      isLoading: true,
    };
  },
  methods: {
   onUserClick() {
     window.location = "http://canpaydebit.com";
   },
    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },

    sendLink() {
      let self = this;
      var request = {
        phone: self.phone,
      };

      api
        .sendLink(request)
        .then((response) => {
          alert(response.message);
        })
        .catch((err) => {
          console.log("error", err);
          alert(err);
        });
    },
  },
};
</script>

<style lang="scss"></style>
